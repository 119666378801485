import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { fetchNlpPropertiesThunk } from "../store/thunks/repliersThunk";
import NLPSearchBar from "./search/NLPSearchBar";
import house10 from "../assets/house 10.png";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const FindYourDream = ({ onSearchSuccess }) => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  // Access NLP status from the Redux slice
  const { nlpStatus, nlpError } = useSelector((state) => state.repliers);

  const handleSearch = async () => {
    if (inputValue.trim()) {
      dispatch(fetchNlpPropertiesThunk(inputValue))
        .unwrap()
        .then((response) => {
          console.log("NLP Search Response:", response);
          onSearchSuccess(response);
        })
        .catch((error) => {
          console.error("Search error:", error);
        });
    }
  };

  return (
    <motion.div
      className="relative mb-12"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <img
        src={house10}
        alt="Featured property"
        className="w-full h-80 md:h-80 object-cover"
      />
      <div className="absolute inset-0 bg-[#0A2342] bg-opacity-70 flex flex-col justify-center items-center text-white p-6 py-16 md:p-12">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">
          Search for your dream home with Synergy AI{" "}
        </h2>
        {/* <p className="text-lg md:text-xl mb-8 text-center">
          Discover personalized property recommendations just for you!
        </p> */}
        <div className="bg-white p-2 flex items-center w-full max-w-xs md:max-w-2xl border border-[#0A2342]">
          <input
            type="text"
            placeholder="Type in what you're looking for (e.g., find me a ...)"
            className="flex-grow px-2 py-2 focus:outline-none text-[#0A2342] mr-2"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <motion.button
            className="p-1"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSearch}>
            <MagnifyingGlassIcon className="text-[#0A2342] h-5 w-5" />
          </motion.button>
        </div>

        {nlpStatus === "loading" && <p>Loading results...</p>}
        {nlpStatus === "failed" && (
          <p className="text-red-500 mt-4">
            {nlpError || "Failed to fetch results. Please try again."}
          </p>
        )}
      </div>
    </motion.div>
  );
};

export default FindYourDream;
