import React, { useMemo, useState } from "react";
import ImageCarousel from "./ImageCarousel";
import { Dialog } from "@headlessui/react";
import noImage from "../../assets/noimage.png";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/outline";

const statusTranslation = {
  New: "New",
  Ter: "Terminated",
  Sld: "Sold",
  Lsd: "Leased",
  Exp: "Expired",
};

const statusStyles = {
  New: "text-blue-600 bg-blue-100",
  Ter: "text-red-600 bg-red-100",
  Sld: "text-green-600 bg-green-100",
  Lsd: "text-green-600 bg-green-100",
  Exp: "text-gray-600 bg-gray-100",
};

const HistoryList = ({ title, history }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);

  const calculateDaysAgo = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    const differenceInDays = Math.floor(
      (today - targetDate) / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays < 0) return null; // Return null for future dates
    if (differenceInDays === 0) return "Today";
    if (differenceInDays === 1) return "A day ago";
    return `${differenceInDays} days ago`;
  };

  const calculatePriceDifference = (listPrice, soldPrice) => {
    const difference = soldPrice - listPrice;
    const percentage = Math.abs(((difference / listPrice) * 100).toFixed(1));
    return { difference, percentage };
  };

  const calculateDaysOnMarket = (listDate, soldDate) => {
    const listTime = new Date(listDate).getTime();
    const soldTime = new Date(soldDate).getTime();
    return Math.floor((soldTime - listTime) / (1000 * 60 * 60 * 24));
  };

  const createSlug = (address) => {
    if (!address) return "";
    const { streetNumber, streetName, city, neighborhood } = address;
    return [streetNumber, streetName, city, neighborhood]
      .filter(Boolean)
      .join("-")
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleViewListing = (item) => {
    const slug = createSlug(item.address);
    const url = `/propertydetails/${item.mlsNumber}/${
      item.boardId || ""
    }/${slug}`;
    window.open(url, "_blank");
  };

  const sortedHistory = useMemo(() => {
    return [...history].sort(
      (a, b) => new Date(b.listDate) - new Date(a.listDate)
    );
  }, [history]);

  const openImageModal = (images) => {
    setCurrentImages(images);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="space-y-8 px-4">
      <h3 className="text-lg font-bold mb-4 text-[#0A2342]">{title}</h3>
      <ul className="space-y-6">
        {sortedHistory.map((item, idx) => {
          const { difference, percentage } =
            item.soldPrice && item.soldDate
              ? calculatePriceDifference(
                  parseFloat(item.listPrice),
                  parseFloat(item.soldPrice)
                )
              : {};

          const daysOnMarket =
            (item.soldDate ||
              item.timestamps?.terminatedDate ||
              item.timestamps?.expiryDate) &&
            calculateDaysOnMarket(
              item.listDate,
              item.soldDate ||
                item.timestamps.terminatedDate ||
                item.timestamps.expiryDate
            );

          return (
            <li
              key={idx}
              className="flex flex-col md:flex-row md:justify-between items-start space-y-6 md:space-y-0 md:space-x-6 border-t border-gray-300 py-6">
              {/* Left Column: Prices and Status */}
              <div className="w-full md:w-1/3">
                <div className="space-y-3">
                  <div className="flex items-center gap-3">
                    <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${
                        statusStyles[item.lastStatus]
                      }`}>
                      {statusTranslation[item.lastStatus] || "Unknown Status"}
                    </span>
                    <h2 className="text-lg font-semibold text-[#0A2342]">
                      {item.lastStatus === "Sld" ? "Sold" : "Listed"}
                    </h2>
                  </div>
                  <div>
                    <p className="text-base font-bold text-[#0A2342]">
                      $
                      {parseFloat(
                        item.soldPrice || item.listPrice
                      ).toLocaleString()}
                      {item.soldPrice && item.soldDate && difference !== 0 && (
                        <span className="text-sm ml-2">
                          <span
                            className={`${
                              difference > 0 ? "text-green-600" : "text-red-600"
                            }`}>
                            (${Math.abs(difference).toLocaleString()} /{" "}
                            {percentage}%{" "}
                            {difference > 0 ? "above asking" : "below asking"})
                          </span>
                        </span>
                      )}
                    </p>
                    <p className="text-xs text-gray-500 mt-1">
                      MLS #{item.mlsNumber}
                    </p>
                  </div>
                </div>
              </div>

              {/* Middle Column: Days */}
              <div className="w-full md:w-1/3">
                <div className="space-y-3">
                  <p className="text-sm text-gray-600">
                    Listed on:{" "}
                    {new Date(item.listDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}{" "}
                    <span className="ml-2 text-gray-400">
                      {calculateDaysAgo(item.listDate)}
                    </span>
                  </p>

                  {item.lastStatus === "Ter" && (
                    <>
                      {item.timestamps.terminatedDate &&
                      item.timestamps.terminatedDate !== "N/A" &&
                      item.timestamps.terminatedDate !== "" ? (
                        <>
                          <p className="text-sm text-gray-600">
                            Terminated on:{" "}
                            {new Date(
                              item.timestamps.terminatedDate
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}{" "}
                            {calculateDaysAgo(
                              item.timestamps.terminatedDate
                            ) && (
                              <span className="ml-2 text-gray-400">
                                {calculateDaysAgo(
                                  item.timestamps.terminatedDate
                                )}
                              </span>
                            )}
                          </p>
                          <p className="text-sm text-gray-600">
                            <strong>Days on Market:</strong> {daysOnMarket} days
                          </p>
                        </>
                      ) : (
                        <p className="text-sm text-gray-600">
                          Termination date unavailable
                        </p>
                      )}
                    </>
                  )}

                  {item.lastStatus === "Exp" && item.timestamps.expiryDate && (
                    <>
                      <p className="text-sm text-gray-600">
                        Expired on:{" "}
                        {new Date(
                          item.timestamps.expiryDate
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}{" "}
                        {calculateDaysAgo(item.timestamps.expiryDate) && (
                          <span className="ml-2 text-gray-400">
                            {calculateDaysAgo(item.timestamps.expiryDate)}
                          </span>
                        )}
                      </p>
                      <p className="text-sm text-gray-600">
                        <strong>Days on Market:</strong> {daysOnMarket} days
                      </p>
                    </>
                  )}
                  {item.soldDate && (
                    <>
                      <p className="text-sm text-gray-600">
                        Sold on:{" "}
                        {new Date(item.soldDate).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}{" "}
                        {calculateDaysAgo(item.soldDate) && (
                          <span className="ml-2 text-gray-400">
                            {calculateDaysAgo(item.soldDate)}
                          </span>
                        )}
                      </p>
                      <p className="text-sm text-gray-600">
                        <strong>Days on Market:</strong> {daysOnMarket} days
                      </p>
                    </>
                  )}
                </div>
              </div>

              {/* Right Column: Actions */}
              <div className="w-full md:w-1/3 flex flex-col md:flex-row md:items-center justify-end gap-2">
                <button
                  onClick={() => openImageModal(item.images)}
                  className="flex-grow md:flex-none text-sm font-medium text-white bg-[#0A2342] px-4 py-2 hover:bg-[#072835] transition">
                  View Images
                </button>
                <button
                  onClick={() => handleViewListing(item)}
                  className="flex-grow md:flex-none text-sm font-medium text-[#0A2342] bg-gray-100 px-4 py-2 hover:bg-gray-200 transition">
                  View Listing
                </button>
              </div>
            </li>
          );
        })}
      </ul>

      {/* Image Modal */}
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <Dialog.Panel className="bg-white p-4 shadow-lg w-4/5 max-w-4xl">
          <button
            onClick={closeModal}
            className="text-sm font-medium text-gray-500 hover:text-gray-700 absolute top-4 right-4">
            Close
          </button>
          <ImageCarousel
            imageUrls={currentImages}
            isGridTile={false}
            className="w-full h-80"
          />
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

export default HistoryList;
