import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ContactModal = ({ isOpen, onClose, user }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("Form submitted");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold text-[#0A2342]">Contact Us</h2>
          <button onClick={onClose}>
            <XMarkIcon className="h-6 w-6 text-gray-500 hover:text-gray-700" />
          </button>
        </div>
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                  defaultValue={`${user.firstName} ${user.lastName}`}
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                  defaultValue={user.username}
                />
                <input
                  type="tel"
                  placeholder="Phone (optional)"
                  className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                />
              </div>
              <textarea
                placeholder="Message"
                rows="4"
                className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                defaultValue={`Hello, my name is ${user.firstName} ${user.lastName} and I am interested in this property. Can I book a showing?`}></textarea>
            </div>
            <div className="mt-4">
              <button className="w-full bg-[#0A2342] text-white py-2 px-4 hover:opacity-90 transition-opacity">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const ContactButton = ({ user }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsContactModalOpen(true)}
        className="z-10 fixed right-6 bottom-6 bg-[#E5B13A] text-[#0A2342] shadow-lg font-semibold border border-[#0A2342] py-2 px-4 mt-4 opacity-100 hover:opacity-80 transition-opacity duration-200">
        Contact Agent
      </button>

      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        user={user}
      />
    </>
  );
};

export default ContactButton;
