import React from "react";

import rei_thumbnail from "../assets/The_Ultimate_Wealth_Building_Investment_Checklist_thumbnail.png";
import sellers_thumbnail from "../assets/The_Ultimate_Home_Sellers_Checklist_thumbnail.png";
import buyers_thumbnail from "../assets/The_Ultimate_Home_Buyers_Checklist_thumbnail.png";

const thumbnails = [buyers_thumbnail, sellers_thumbnail, rei_thumbnail];
const pdfs = [
  {
    name: "The Ultimate Home Buyers Checklist",
    url: "/pdfs/The%20Ultimate%20Home%20Buyers%20Checklist.pdf",
  },
  {
    name: "The Ultimate Home Sellers Checklist",
    url: "/pdfs/The%20Ultimate%20Home%20Sellers%20Checklist.pdf",
  },
  {
    name: "The Ultimate Wealth Building Investment Checklist",
    url: "/pdfs/The%20Ultimate%20Wealth%20Building%20Investment%20Checklist.pdf",
  },
];

export default function LearnPage() {
  const handleDownload = (pdf) => {
    const a = document.createElement("a");
    a.href = pdf.url;
    a.download = pdf.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <section aria-labelledby="details-heading">
          <div className="flex flex-col items-center text-center">
            <h2
              id="details-heading"
              className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Want to learn more about the homebuying process?
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-600">
              Level up your real estate expertise using these resources curated
              by the Synergy Real Estate team.
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 gap-y-16 lg:grid-cols-3 lg:gap-x-8 justify-items-center">
            {pdfs.map((pdf, index) => (
              <div
                key={index}
                className="border border-[#0A2342] p-4 w-full flex flex-col justify-between items-center">
                <img
                  src={thumbnails[index]}
                  alt={`${pdf.name} thumbnail`}
                  className="w-full h-auto"
                />

                <h3 className="mt-2 text-base font-semibold text-[#0A2342] break-words">
                  {pdf.name}
                </h3>

                {/* Trigger download via Blob */}
                <button
                  onClick={() => handleDownload(pdf)}
                  className="mt-2 bg-[#0A2342] text-white py-2 px-4 hover:bg-gray-600 border border-[#0A2342]">
                  Download PDF
                </button>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
