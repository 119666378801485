import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPersonalizedPropertiesThunk,
  fetchAggregatesThunk,
} from "../store/thunks/repliersThunk";
import PropertyCarousel from "../components/properties/PropertyCarousel";
import FindYourDream from "../components/FindYourDream";
import LoadingSpinner from "../components/LoadingSpinner";
// import SearchBar from "../components/search/SearchBar";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const { nlpSearchResults, nlpStatus, nlpSearchSummary } = useSelector(
    (state) => state.repliers
  );

  const { fullTextSearchResults, fullTextSearchStatus, fullTextSearchError } =
    useSelector((state) => state.repliers);

  const searchPropertyResults = useSelector(
    (state) => state.repliers.searchPropertyResults
  );

  const [generalListings, setGeneralListings] = useState([]);
  const [personalizedListings, setPersonalizedListings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (pageNum) => {
      setLoading(true);

      // Check if tokens array exists and has tokens
      const currentToken = user?.tokens?.[user.tokens.length - 1]?.token;

      if (!currentToken) {
        console.error("Token is undefined");
        setLoading(false);
        return;
      }

      try {
        const personalizedResult = await dispatch(
          fetchPersonalizedPropertiesThunk({ pageNum })
        ).unwrap();
        setPersonalizedListings(personalizedResult.listings || []);
      } catch (error) {
        console.error("Error fetching personalized properties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, user]);

  // useEffect(() => {
  //   const fetchAggregatesData = async () => {
  //     try {
  //       const result = await dispatch(fetchAggregatesThunk()).unwrap();
  //       console.log("Fetched aggregates successfully:", result);
  //     } catch (error) {
  //       console.error("Error fetching aggregates:", error);
  //     }
  //   };

  //   fetchAggregatesData();
  // }, [dispatch]);

  return (
    <div className="bg-gray-100 min-h-screen">
      <main className="container mx-auto py-8">
        <FindYourDream />

        {loading ? (
          <div className="text-center">
            <div className="loader"></div>
            {/* <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
              Gathering property info...
            </h2> */}
            <LoadingSpinner title={"Curating your dashboard"} />
          </div>
        ) : (
          <>
            {/* No Listings Available */}
            {!personalizedListings.length &&
              !generalListings.length &&
              !nlpSearchResults?.length &&
              !fullTextSearchResults?.listings?.length && (
                <div className="text-center">
                  <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
                    No properties available at the moment. Try logging out and
                    in again.
                  </h2>
                </div>
              )}

            {/* NLP Listings */}
            {nlpStatus === "succeeded" &&
              nlpSearchResults?.listings.length > 0 && (
                <PropertyCarousel
                  title={`NLP Listings [${nlpSearchResults.count}]`}
                  properties={nlpSearchResults.listings}
                  description={nlpSearchSummary}
                  pageSize={10}
                />
              )}

            {/* Full Text Search Properties */}
            {fullTextSearchStatus === "succeeded" &&
              fullTextSearchResults?.listings?.length > 0 && (
                <PropertyCarousel
                  title="Searched Properties"
                  properties={fullTextSearchResults.listings}
                  description="These are listings from your search."
                  pageSize={10}
                />
              )}

            {/* Personalized Recommendations */}
            <PropertyCarousel
              title="Personalized Properties"
              description="These are personalized recommendations based on your onboarding preferences."
              pageSize={20}
              fetchType="personalized"
            />

            <PropertyCarousel
              title="Recently Sold"
              description="Properties sold in the last 60 days that match your preferences."
              pageSize={20}
              fetchType="personalizedSold"
            />

            <PropertyCarousel
              title="Recently Listed"
              description="Properties listed in the last 60 days that match your preferences."
              pageSize={20}
              fetchType="personalizedList"
            />

            {/* General Recommendations */}
            <PropertyCarousel
              title="General Recommendations"
              description="These are general recommendations."
              pageSize={20}
              fetchType="general"
            />
          </>
        )}
      </main>
    </div>
  );
};

export default DashboardPage;
