import React from "react";
import {
  ArrowTopRightOnSquareIcon,
  MapIcon,
} from "@heroicons/react/24/outline";

const LocalPlaceCard = ({
  name,
  type,
  distance,
  score,
  walking_time,
  address,
  facilities,
  url,
  onMapClick,
  lineClamp = 2,
}) => (
  <div className="min-w-[250px] mb-4 pb-4 border-b flex-shrink-0">
    <div className="flex items-center space-x-2">
      <h4 className="font-semibold text-[#0A2342] truncate">
        {(type?.toString().toLowerCase() === "police" ? "Police - " : "") +
          name}
      </h4>
      {onMapClick && (
        <MapIcon
          onClick={onMapClick}
          className="h-5 w-5 text-[#0A2342] cursor-pointer ml-2"
        />
      )}
      {url && (
        <ArrowTopRightOnSquareIcon
          onClick={() => window.open(url, "_blank")}
          className="h-5 w-5 text-[#0A2342] cursor-pointer"
        />
      )}
    </div>

    {distance && (
      <p className="text-sm text-gray-600 truncate">
        {distance} km{" "}
        {walking_time
          ? `• ${Math.round(walking_time * 10) / 10} mins`
          : address && `• ${address}`}
      </p>
    )}

    {facilities && (
      <p className={`text-sm text-gray-600 line-clamp-${lineClamp}`}>
        {facilities}
      </p>
    )}

    {score && score !== null && (
      <div className="flex items-center flex mt-2">
        <div className="relative w-[100%] flex flex-row bg-gray-400 bg-opacity-75 h-5 mr-2">
          <div
            className="bg-[#0A2342] h-5 "
            style={{
              width: `${(score ?? 0) * 10}%`,
            }}></div>
          <span
            className="absolute inset-0 flex items-center justify-center text-xs text-white font-semibold"
            style={{
              // color: score >= 5 ? "white" : "#0A2342", // Ensure text visibility
              color: "white",
            }}>
            {/* {score ?? "N/A"}/10 */}
            <span className="text-sm font-semibold">{score ?? "N/A"}/10</span>
          </span>
        </div>
      </div>
    )}
  </div>
);

export default LocalPlaceCard;
