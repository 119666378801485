import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ReferAndEarnPage = () => {
  const [referralLink, setReferralLink] = useState('');
  const [copied, setCopied] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    // Generate the referral link
    const baseUrl = window.location.origin;
    const referralCode = user?.referralCode || user._id || 'default';
    setReferralLink(`${baseUrl}/signup?ref=${referralCode}`);
  }, [user]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleShare = (platform) => {
    let shareUrl;
    const message = `Join me on our platform using my referral link: ${referralLink}`;

    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`;
        break;
      case 'instagram':
        // Note: Instagram doesn't have a direct share URL, so we'll copy the message to clipboard
        navigator.clipboard.writeText(message);
        alert('Message copied to clipboard. Open Instagram and paste to share!');
        return;
      case 'sms':
        shareUrl = `sms:?body=${encodeURIComponent(message)}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  };

  const [referralStats, setReferralStats] = useState({
    totalReferrals: 0,
    pendingReferrals: 0,
    totalRewards: 0,
  });

  useEffect(() => {
    // Fetch referral stats from your API
    const fetchReferralStats = async () => {
      try {
        const response = await fetch('/api/referral-stats');
        const data = await response.json();
        setReferralStats(data);
      } catch (error) {
        console.error('Error fetching referral stats:', error);
      }
    };

    fetchReferralStats();
  }, []);

  return (
    <>
      <div className="max-w-4xl mx-auto p-6 mt-20">
        <h1 className="text-3xl font-bold mb-6">Refer & Earn</h1>
        
        <ul className="list-none space-y-4 mb-8">
          <li className="flex items-start">
            <svg className="w-6 h-6 text-green-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>Share your unique referral link with friends & family</span>
          </li>
          <li className="flex items-start">
            <svg className="w-6 h-6 text-green-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>They buy a property through Synergy AI</span>
          </li>
          <li className="flex items-start">
            <svg className="w-6 h-6 text-green-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>You earn $500 immediately!</span>
          </li>
        </ul>

        <p className="mb-4">Share your unique referral link with friends and family:</p>
        <div className="flex items-center mb-6">
          <input
            type="text"
            value={referralLink}
            readOnly
            className="flex-grow p-2 border rounded-l"
          />
          <CopyToClipboard text={referralLink} onCopy={handleCopy}>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600">
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </CopyToClipboard>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Share via:</h2>
          <div className="flex space-x-4">
            <button
              onClick={() => handleShare('facebook')}
              className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
              aria-label="Share on Facebook"
            >
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </button>
            <button
              onClick={() => handleShare('instagram')}
              className="bg-pink-600 text-white p-2 rounded hover:bg-pink-700"
              aria-label="Share on Instagram"
            >
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
              </svg>
            </button>
            <button
              onClick={() => handleShare('sms')}
              className="bg-green-600 text-white p-2 rounded hover:bg-green-700"
              aria-label="Share via SMS"
            >
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17L4 17.17V4h16v12zM7 9h2v2H7zm8 0h2v2h-2zm-4 0h2v2h-2z"/>
              </svg>
            </button>
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">Your Referral Stats</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-white p-4 rounded shadow">
              <h3 className="text-lg font-medium">Total Referrals</h3>
              <p className="text-3xl font-bold">{referralStats.totalReferrals}</p>
            </div>
            <div className="bg-white p-4 rounded shadow">
              <h3 className="text-lg font-medium">Pending Referrals</h3>
              <p className="text-3xl font-bold">{referralStats.pendingReferrals}</p>
            </div>
            <div className="bg-white p-4 rounded shadow">
              <h3 className="text-lg font-medium">Total Rewards</h3>
              <p className="text-3xl font-bold">${referralStats.totalRewards}</p>
            </div>
          </div>
        </div>
        {/* Add more content here, such as referral stats, rewards info, etc. */}
      </div>
    </>
  );
};

export default ReferAndEarnPage;