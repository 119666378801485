import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecommendationsThunk } from "../../store/thunks/recommenderThunk";

const RecommenderTest = ({ mlsNumber }) => {
  const dispatch = useDispatch();
  const { recommendations, recommendationsStatus, recommendationsError } =
    useSelector((state) => state.recommender);

  useEffect(() => {
    if (mlsNumber) {
      dispatch(fetchRecommendationsThunk({ mlsNumber }));
    }
  }, [mlsNumber, dispatch]);

  if (recommendationsStatus === "loading") return <p>Loading...</p>;
  if (recommendationsStatus === "failed")
    return <p>Error: {recommendationsError}</p>;

  return (
    <div>
      <h3>Recommendations for MLS #{mlsNumber}</h3>
      <ul>
        {recommendations.map((rec) => (
          <li key={rec.mlsNumber}>
            {rec.details?.propertyType} at ${rec.listPrice}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecommenderTest;
