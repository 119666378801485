import { createSlice } from "@reduxjs/toolkit";
import { fetchRecommendationsThunk } from "../thunks/recommenderThunk";

const recommenderSlice = createSlice({
  name: "recommender",
  initialState: {
    recommendations: [],
    recommendationsStatus: "idle",
    recommendationsError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecommendationsThunk.pending, (state) => {
        state.recommendationsStatus = "loading";
        state.recommendationsError = null;
      })
      .addCase(fetchRecommendationsThunk.fulfilled, (state, action) => {
        state.recommendationsStatus = "succeeded";
        state.recommendations = action.payload;
      })
      .addCase(fetchRecommendationsThunk.rejected, (state, action) => {
        state.recommendationsStatus = "failed";
        state.recommendationsError = action.payload || action.error.message;
      });
  },
});

export default recommenderSlice.reducer;
