import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutThunk } from "../store/thunks/authThunk";
import { motion } from "framer-motion";
import { Disclosure, Menu } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  GiftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import SearchBar from "./search/SearchBar";
import logo from "../assets/logo-small.png";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [showSearch, setShowSearch] = useState(false);

  const navigation = [
    {
      name: "Home",
      href: "/dashboard",
      current: window.location.pathname === "/dashboard",
    },
    {
      name: "Discover",
      href: "/discover",
      current: window.location.pathname === "/discover",
    },
    {
      name: "Favourites",
      href: "/favourites",
      current: window.location.pathname === "/favourites",
    },
    {
      name: "Realtors",
      href: "/realtors",
      current: window.location.pathname === "/realtors",
    },
    {
      name: "Learn",
      href: "/learn",
      current: window.location.pathname === "/learn",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleLogout = async (e) => {
    e.preventDefault();
    navigate("/loading", {
      state: { message: "Logging out...", duration: 1500, redirectTo: "/" },
    });
    try {
      await dispatch(logoutThunk()).unwrap();
      navigate("/loading", {
        state: {
          message: "Logged out successfully",
          duration: 1000,
          redirectTo: "/",
        },
      });
    } catch (err) {
      console.error("Failed to logout: ", err);
    }
  };

  const userNavigation = [
    { name: "Settings", href: "/settings" },
    { name: "Preferences", href: "/preferences" },
    { name: "Sign out", href: "#", onClick: handleLogout },
  ];

  const handleNavigation = (e, item, closeMenu) => {
    e.preventDefault();
    navigate(item.href);
    if (closeMenu) closeMenu();
  };

  return (
    <div className="mb-16">
      <Disclosure
        as="nav"
        className="fixed z-50 bg-white border-b border-[#0A2342] shadow-lg w-full">
        {({ open, close }) => (
          <>
            <div className="mx-auto w-full px-2 sm:px-4 lg:px-8">
              <div className="flex h-16 items-center justify-between relative">
                {/* Left Section: Logo */}
                <div className="flex items-center relative">
                  <Link
                    to="/dashboard"
                    onClick={() => navigate("/dashboard")}
                    className="flex-shrink-0">
                    <img
                      src={logo}
                      className="h-8 w-auto sm:h-12"
                      alt="Synergy AI"
                    />
                  </Link>

                  {/* Desktop Navigation */}
                  <div className="hidden md:flex md:space-x-4 lg:space-x-6 ml-4 overflow-x-auto">
                    {navigation.map((item) => (
                      <motion.a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => handleNavigation(e, item)}
                        className={classNames(
                          window.location.pathname.endsWith(item.href)
                            ? "text-[#0A2342]"
                            : "text-gray-500 hover:text-[#0A2342]",
                          "text-sm transition-colors duration-200 whitespace-nowrap"
                        )}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}>
                        {item.name}
                      </motion.a>
                    ))}
                  </div>
                </div>

                {/* Desktop Search Bar */}
                <div className="hidden md:block absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-full max-w-lg z-10">
                  <SearchBar />
                </div>

                {/* Mobile Search Icon */}
                <div className="md:hidden translate-x-[26px] flex items-center justify-center">
                  <button
                    onClick={() => setShowSearch((prev) => !prev)}
                    className="text-gray-400 hover:text-[#0A2342]">
                    <MagnifyingGlassIcon className="h-6 w-6" />
                  </button>
                </div>

                {/* Right Section: User Menu and Burger Menu */}
                <div className="flex items-center space-x-2 sm:space-x-4">
                  <div className="hidden sm:flex items-center space-x-4">
                    <motion.a
                      href="/refer-and-earn?utm_source=referral"
                      className="text-gray-400 hover:text-[#0A2342]"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}>
                      <GiftIcon className="h-6 w-6" />
                    </motion.a>
                    <motion.button
                      type="button"
                      className="text-gray-400 hover:text-[#0A2342]"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}>
                      <BellIcon className="h-6 w-6" />
                    </motion.button>
                  </div>

                  <Menu as="div" className="relative z-50">
                    <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2">
                      <div className="h-8 w-8 rounded-full bg-white flex items-center justify-center text-[#0A2342] border border-[#0A2342]">
                        {user?.firstName?.charAt(0)}
                        {user?.lastName?.charAt(0)}
                      </div>
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-300 font-semibold text-[#0A2342] text-md"
                                  : "text-gray-500",
                                "block px-4 py-2 text-sm"
                              )}>
                              <a
                                href={item.href}
                                onClick={item.onClick}
                                className="block w-full h-full">
                                {item.name}
                              </a>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>

                  <div className="md:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 hover:bg-gray-200 hover:text-[#0A2342] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#0A2342]">
                      {open ? (
                        <XMarkIcon className="h-6 w-6" />
                      ) : (
                        <Bars3Icon className="h-6 w-6" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile Search Bar */}
            {showSearch && (
              <div className="absolute top-16 w-full z-50 bg-white shadow-lg">
                <SearchBar />
              </div>
            )}

            {/* Mobile Menu */}
            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3">
                <SearchBar />
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    onClick={(e) => {
                      handleNavigation(e, item, close);
                    }}
                    className={classNames(
                      item.current
                        ? "bg-gray-50 text-[#0A2342]"
                        : "text-gray-500 hover:bg-gray-200 hover:text-[#0A2342]",
                      "block px-3 py-2 text-base font-medium rounded-md"
                    )}>
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-200 my-2"></div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="/refer-and-earn?utm_source=referral"
                  onClick={close}
                  className="block px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-200 hover:text-[#0A2342] rounded-md">
                  <GiftIcon className="h-6 w-6 inline mr-2" />
                  Refer & Earn
                </Disclosure.Button>
                <Disclosure.Button
                  as="button"
                  className="w-full text-left px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-200 hover:text-[#0A2342] rounded-md"
                  onClick={close}>
                  <BellIcon className="h-6 w-6 inline mr-2" />
                  Notifications
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Header;
