import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector, Provider } from "react-redux";
import { fetchLandingPage } from "./store/thunks/dataThunk";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for styling
import "./styles/toastStyles.css"; // Import your custom styles

// Importing all the pages
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import SettingsPage from "./pages/SettingsPage";
import PropertyDetailsPage from "./pages/PropertyDetailsPage";
import DashboardPage from "./pages/DashboardPage";
import OnboardingPage from "./pages/OnboardingPage";
import DiscoverPage from "./pages/DiscoverPage";
import RealtorPage from "./pages/RealtorPage";
import LearnPage from "./pages/LearnPage";
import NotFoundPage from "./pages/NotFoundPage";
import ReferAndEarnPage from "./pages/ReferAndEarnPage";
import FavouritesPage from "./pages/FavouritesPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";

// Importing all the components
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoadingScreen from "./components/LoadingScreen";

// Create a PrivateRoute component for protected pages
const PrivateRoute = ({ children, hideHeader }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? (
    <>
      {!hideHeader && <Header />}
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">{children}</div>
        <Footer />
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLandingPage());
  }, [dispatch]);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin + "/dashboard"}
      cacheLocation="localstorage">
      <Router>
        <div className="bg-white flex flex-col min-h-screen overscroll-none overflow-none">
          <main className="isolate flex-grow">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="*" element={<NotFoundPage />} />
              {/* Private Routes (Require Authentication) */}
              <Route
                path="/preferences"
                element={
                  <PrivateRoute hideHeader>
                    <OnboardingPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <DashboardPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/propertydetails/"
                element={
                  <PrivateRoute>
                    <PropertyDetailsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/propertydetails/:mlsNumber/:boardId/*"
                element={
                  <PrivateRoute>
                    <PropertyDetailsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/discover"
                element={
                  <PrivateRoute>
                    <DiscoverPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/realtors"
                element={
                  <PrivateRoute>
                    <RealtorPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/learn"
                element={
                  <PrivateRoute>
                    <LearnPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/favourites"
                element={
                  <PrivateRoute>
                    <FavouritesPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <SettingsPage />
                  </PrivateRoute>
                }
              />
              <Route path="/loading" element={<LoadingScreen />} />
              <Route
                path="/refer-and-earn"
                element={
                  <PrivateRoute>
                    <ReferAndEarnPage />
                  </PrivateRoute>
                }
              />
            </Routes>
          </main>
        </div>
      </Router>
      <ToastContainer />
    </Auth0Provider>
  );
}

export default App;
