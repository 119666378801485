import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../assets/Logo-Transparent-With-Text.png";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitError("");
    
    try {
      // TODO: Implement your password reset API call here
      // const response = await api.sendPasswordResetEmail(email);
      setIsSubmitted(true);
    } catch (error) {
      setSubmitError(error.message || "An error occurred. Please try again.");
    }
  };

  if (isSubmitted) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <motion.div
          className="max-w-md w-full space-y-8 bg-white p-10 shadow-lg"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}>
          <div className="text-center">
            <h2 className="text-2xl font-bold text-[#0A2342] mb-4">Check Your Email</h2>
            <p className="text-gray-600 mb-4">
              If an account exists for {email}, you will receive a password reset link.
            </p>
            <motion.button
              onClick={() => navigate("/login")}
              className="text-[#0A2342] hover:text-[#1C4B82]"
              whileHover={{ scale: 1.05 }}>
              Return to login
            </motion.button>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <div>
          <img
            className="mx-auto h-12 w-auto cursor-pointer"
            src={logo}
            alt="Synergy Real Estate Logo"
            width={48}
            height={48}
            onClick={() => navigate("/")}
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-[#0A2342]">
            Reset your password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Enter your email address and we'll send you a link to reset your password.
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:border-[#0A2342] focus:z-10 sm:text-sm"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {submitError && (
            <p className="text-center text-sm text-red-600">{submitError}</p>
          )}

          <div>
            <motion.button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-[#0A2342] hover:bg-[#1C4B82] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0A2342]"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              Send reset link
            </motion.button>
          </div>
        </form>

        <div className="text-center">
          <motion.button
            onClick={() => navigate("/login")}
            className="text-sm text-[#0A2342] hover:text-[#1C4B82]"
            whileHover={{ scale: 1.05 }}>
            Back to login
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
}