import React, { useMemo } from "react";
import HistoryList from "./HistoryList";

const PropertyHistory = ({ history }) => {
  // Separate sale and lease histories
  const { saleHistory, leaseHistory } = useMemo(() => {
    return {
      saleHistory: history.filter((item) => item.type === "Sale"),
      leaseHistory: history.filter((item) => item.type === "Lease"),
    };
  }, [history]);

  return (
    <div className="space-y-8">
      {saleHistory.length > 0 && (
        <HistoryList title="Sale History" history={saleHistory} />
      )}
      {leaseHistory.length > 0 && (
        <HistoryList title="Lease History" history={leaseHistory} />
      )}
    </div>
  );
};

export default PropertyHistory;
