import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import dataReducer from "./slices/dataSlice";
import authReducer from "./slices/authSlice";
import onboardingReducer from "./slices/onboardingSlice";
// import pdfsReducer from "./slices/pdfsSlice";
import repliersReducer from "./slices/repliersSlice";
import favoritesReducer from "./slices/favoritesSlice";
import emailReducer from "./slices/emailSlice";
import recommenderReducer from "./slices/recommenderSlice";

const persistConfig = {
  key: "propulsionai",
  storage,
  whitelist: ["auth", "userPreferences", "favorites"],
};

const rootReducer = combineReducers({
  data: dataReducer,
  auth: authReducer,
  onboarding: onboardingReducer,
  // pdfs: pdfsReducer,
  repliers: repliersReducer,
  favorites: favoritesReducer,
  email: emailReducer,
  recommender: recommenderReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./thunks/dataThunk";
export * from "./thunks/authThunk";
export * from "./thunks/onboardingThunk";
// export * from "./thunks/pdfsThunk";
export * from "./thunks/repliersThunk";
export * from "./thunks/recommenderThunk";
